import Button from '/components/buttons/Button';

import Footer from '../components/Footer';

export default function NotFoundPage() {
    return (
        <div className="single-page home">
            <div className="py-28 text-center space-y-8">
                <h1>Ta stran ni bila najdena.</h1>
                <Button variant="contained" color="secondary" link="/">
                    Na domačo stran
                </Button>
            </div>

            <div className="mt-24">
                <Footer />
            </div>
        </div>
    );
}
